import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
const services = [
    {
      name: "Creative Direction",
      desc: "Expertise in leading and managing creative teams to deliver exceptional content across multiple channels, including TV, radio, print, digital, and social media.",
      icon: "fas fa-lightbulb",
    },
    {
      name: "Content Production",
      desc: "Experience in producing engaging content for various media platforms, optimizing production processes and achieving significant cost savings.",
      icon: "fas fa-video",
    },
    {
      name: "Video & Photography",
      desc: "In-house video and photography studio creation, leveraging internal talent for broadcast-quality commercials and other visual projects.",
      icon: "fas fa-camera",
    },
    {
      name: "Marketing & Analytics",
      desc: "Proven success in utilizing marketing tools and analytics to drive lead generation, engagement, and performance optimization.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Project Management",
      desc: "Skilled in using project management tools and methodologies to ensure efficient execution and delivery of creative projects.",
      icon: "fas fa-tasks",
    },
    {
      name: "Cross-discipline Team Building",
      desc: "Adept at building cross-discipline teams, fostering collaboration, and bridging communications between creatives and marketing stakeholders.",
      icon: "fas fa-users",
    },
  ];


  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Skills
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Excel At
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
