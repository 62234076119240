import React from "react";
import resumeFile from "../documents/resume.pdf";
import logo1 from "../documents/client-logo1.svg";
import logo2 from "../documents/client-logo2.svg";
import logo3 from "../documents/client-logo3.svg";
import logo4 from "../documents/client-logo4.svg";

// Import the SVGs as React components
import { ReactComponent as GraduateLogo1 } from '../documents/graduate-logo1.svg';
import { ReactComponent as GraduateLogo2 } from '../documents/graduate-logo2.svg';
import { ReactComponent as GraduateLogo3 } from '../documents/graduate-logo3.svg';

const LogosRow = () => {
  const logos = [logo1, logo2, logo3, logo4];
  const logoHeight = "4.5em"; // Change this to adjust the logo height
  const logoWidth = "auto"; // Change this to adjust the logo width

  return (
    <div className="row mx-0 mb-5 justify-content-center">
      {logos.map((logo, index) => (
        <div className="col-lg-2 col-md-3 col-6 mb-4 mb-lg-0 d-flex justify-content-center" key={index}>
          <img
            src={logo}
            alt={`Logo ${index}`}
            className="img-fluid"
            style={{ height: logoHeight, width: logoWidth, objectFit: "contain", margin: "0 0.5em" }}
          />
        </div>
      ))}
    </div>
  );
};

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2005 - 2007",
      title: "Industrial Design and Architecture",
      place: "Facoltà di Architettura Ludovico Quaroni, Rome, Italy",
      desc: "Studied Industrial Design, focusing on product development, graphic design, and process.",
    },
    {
      yearRange: "2013 - 2017",
      title: "Bachelor of Arts, Minor in Marketing and TV Production",
      place: "Columbia College Chicago",
      desc: "Graduated with a 3.96 GPA, Magna Cum Laude, Honors, and Dean’s List, focusing on marketing and TV production.",
    },
    {
      yearRange: "2018, Now",
      title: "Certifications",
      place: "Google, PMI, Blackmagic Design",
      desc: "Completed Google Ad Manager, Google Ads Video, Google Analytics Individual Qualification, and YouTube Ads certifications to further enhance marketing skills.",
    },
  ];

  const graduateLogos = [
    {
      logo: GraduateLogo1,
      label: "YCombinator Startup School",
    },
    {
      logo: GraduateLogo2,
      label: "The Second City Training Center",
    },
    {
      logo: GraduateLogo3,
      label: "iO Training Center",
    },
  ];

const experienceDetails = [
    {
      yearRange: "2008 - 2015",
      title: "Content Producer & Host",
      place: "Rai – Radiotelevisione Italiana S.p.A.",
      desc: "Directed teams of up to 9 people, created original shows and video segments, reaching over 248M combined organic views during the tenure.",
    },
    {
      yearRange: "2014 - 2017",
      title: "Content Consultant",
      place: "Viacom Networks (Comedy Central)",
      desc: "Created, produced, and starred in 3 recurring segments for the original TV program “Comedy Central News” on Viacom’s EMEA networks.",
    },
    {
      yearRange: "2017 - 2018",
      title: "Content Producer",
      place: "Discovery Networks",
      desc: "Led the development of a video series, resulting in ~300% cheaper cost per episode than traditional studio productions and accumulated over 1M organic views per episode average over 2 seasons.",
    },
    {
      yearRange: "2019 - Present",
      title: "Senior Creative Marketing Manager",
      place: "GoHealth",
      desc: "Led and built a team of creatives to develop efficient marketing deliverables across multiple channels, built an in-house video and photography studio, created a framework for content teams to scale and optimize video production, and implemented creative project management best practices.",
    },
  ];

  const skills = [
    {
      name: "Project Management & Leadership",
      description: "Agile, Kanban, Jira, Smartsheet, Trello, Asana, Notion, Monday, Obsidian — Balancing Workloads, Defining Milestones, Workflows & Conventions, Building Cross-discipline Teams — Coordination, Planning, Removing Barriers, Calmness",
    },
    {
      name: "Marketing & Analytics",
      description: "Google Ads, Google Analytics, AHREFS, GPT (Jasper, OpenAI, LLMs, prompt engineering), Direct Response, TrueView, Lead Gen, Performance, A/B Testing, Landing Page Optimization — Hootsuite, Sprout, Hypefury",
    },
    {
      name: "Video Production & Photography",
      description: "Camera, Direction, Crew Management, Interviewing, Story Producing, Media Management, Compression, Delivery Workflows — Collaborative Copywriting, Timelines, Planning",
    },
    {
      name: "Design & Software",
      description: "Adobe Creative Suite (Photoshop, InDesign, Illustrator, Premiere Pro, After Effects) — iZotope RX, Logic Pro, Blackmagic DaVinci Resolve, Descript, Microsoft Office, Sharepoint, WordPress, Ghost, Figma, Sketch — Markdown, HTML, CSS",
    },
    {
      name: "Soft Skills",
      description: "Improvisation, Simplicity, Scalability, Performance Tuning",
    },
  ];
  

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Résumé
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* Logos row */}
        <LogosRow />
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
{/* A Graduate Of */}
    <div className="col-12">
      <h2 className={"text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")}>
        A Graduate Of
      </h2>
      {graduateLogos.map(({ logo: GraduateLogo, label }, index) => (
        <div 
          key={index} 
          className="d-flex justify-content-center mb-4"
          style={{ width: '100%', paddingBottom: '1em' }}
        >
          <GraduateLogo aria-label={label} style={{ width: "50%", height: "auto" }}/>
        </div>
      ))}
    </div>
          </div>

          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2 className={"text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")}>
          My Skills
        </h2>
        <div className="row">
          <div className="col-md-6">
            {skills.slice(0, Math.ceil(skills.length / 2)).map((skill, index) => (
              <div key={index} className="mb-8">
                <h3 className={"text-2xl font-bold mb-2 " + (darkTheme ? "text-white" : "text-gray-800")}>{skill.name}</h3>
                <p className={"text-lg mb-4 " + (darkTheme ? "text-white" : "text-gray-800")}>{skill.description}</p>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            {skills.slice(Math.ceil(skills.length / 2)).map((skill, index) => (
              <div key={index} className="mb-8">
                <h3 className={"text-2xl font-bold mb-2 " + (darkTheme ? "text-white" : "text-gray-800")}>{skill.name}</h3>
                <p className={"text-lg mb-4 " + (darkTheme ? "text-white" : "text-gray-800")}>{skill.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Get full résumé (PDF)
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;

