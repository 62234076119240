import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
          className={
            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0" +
            (darkTheme ? "text-white" : "text-dark")
          }
        >
          An Innovative, Results-driven Mind
          <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
        </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
        {/* Profile image start */}
        <div className="col-12 text-center my-5">
          <span className="bg-dark-2 rounded-pill p-2 mb-lg-1 d-inline-block">
            <img
              className="img-fluid rounded-pill d-block"
              src="images/profile.jpg"
              title="I’m Simone"
              alt="profile"
            />
          </span>
        </div>
        {/* Profile image end */}
        {/* About me content start */}
        <div className="col-lg-7 col-xl-8 text-center text-lg-start">
          <h2
            className={
              "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
            }
          >
            I’m <span className="text-primary">Simone Salis</span>, a Creative Leader & Marketing Expert
          </h2>
          <p className={darkTheme ? "text-white-50" : ""}>
            I specialize in driving creative efficiency aimed at concrete KPIs from conception to execution. With over 12 years of experience in managing teams and executing content projects, I’ve worked with renowned companies like GoHealth, Discovery Networks, and Comedy Central.
          </p>
          <p className={darkTheme ? "text-white-50" : ""}>
            My goal is to create innovative solutions for today’s dynamic media landscape, leveraging my skills in video production, marketing, and project management. I’m passionate about delivering exceptional results within time and budget, meeting clients’ requirements, and exceeding expectations.
          </p>
        </div>
        {/* About me content end */}
        {/* about me personal details start */}
        <div className="col-lg-5 col-xl-4">
          <div className="ps-lg-4">
            <ul
              className={
                "list-style-2 " +
                (darkTheme ? "list-style-light text-light" : "")
              }
            >
              <li>
                <span className="fw-600 me-2">Name:</span>Simone Salis
              </li>
              <li>
                <span className="fw-600 me-2">Email:</span>
                <a href="mailto:salis@simone.org">simone@salis.media</a>
              </li>
              <li>
                <span className="fw-600 me-2">Location:</span>Chicago, United States
              </li>
              <li className="border-0">
                <span className="fw-600 me-2">LinkedIn:</span>
                <a href="https://www.linkedin.com/in/simsalis" target="_blank" rel="noopener noreferrer">in/simsalis</a>
              </li>
            </ul>
            <a
              href={resumeFile}
              download
              className="btn btn-primary rounded-pill"
            >
              Download PDF résumé
            </a>
          </div>
        </div>
        {/* about me personal details end */}

        </div>
{/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>12</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>4</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Major Companies Worked With
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>248M</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Organic Views Generated
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>98+</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Media Lead Gen Campaigns Produced
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}

      </div>
    </section>
  );
};

export default AboutUs;
