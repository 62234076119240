import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
const reviews = [
  {
    name: "Melissa Balsan",
    position: "Executive VP and CMO at Vericity, Inc.",
    src: "images/testimonial/melissa-balsan.jpg",
    desc: "Simone is incredibly talented and creatively masterful across a number of in-demand interactive media. His iterative design approach is direct-response oriented and data-driven, and he seeks data that validates measurable performance impact at every turn. Beyond these important skills and abilities, he’s also agile and operates well despite ambiguity—attributes which are extremely valuable in an ever-evolving business climate. Simone is a pleasure to work with and I highly recommend him!",
    rating: 5,
  },
  {
    name: "Theresa Le",
    position: "BX Designer at Vokal, Inc.",
    src: "images/testimonial/theresa-le.jpg",
    desc: "A passionate creative with phenomenal communication and managerial skills. His leadership is proactive while being welcoming, understanding, and patient. I can vouch for his technical and tactical experience in production from planning and shooting to editing. Anyone would be lucky to have Simone on board!",
    rating: 5,
  },
  {
    name: "Craig Heron",
    position: "VP at Beeso Studio, LLC",
    src: "images/testimonial/craig-heron.jpg",
    desc: "Simone is a thoughtful and strategic thinker. He thinks through processes like an engineer and is sure to add strategic value to any organization. He’s also a great person to be around with an infectious energy.",
    rating: 5,
  },
];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Recommendations
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Don’t Just Take My Word For It
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
