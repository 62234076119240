import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
<div className="modal-body p-4">
            <p>
              All original content on this website, including text, images, and design elements, are the property of Simone Salis and are protected by copyright. Unauthorized use or distribution is strictly prohibited.
            </p>
            <ul className="lh-lg">
              <li>
                Any reproduction, distribution, or use of the content on this website without the express written consent of Simone Salis is strictly prohibited.
              </li>
              <li>
                The information provided on this website is for informational purposes only and is subject to change without notice. While every effort has been made to ensure the accuracy of the content, errors or omissions may occur.
              </li>
              <li>
                Simone Salis assumes no responsibility for any loss or damage resulting from the use of this website or its content.
              </li>
              <li>
                This website may contain links to third-party websites. Simone Salis is not responsible for the content or privacy practices of those websites and does not endorse or assume responsibility for their content.
              </li>
              <li>
                The testimonials and endorsements found on this website are genuine, but they do not guarantee similar results or experiences for all users. Individual results may vary.
              </li>
              <li>
                All trademarks, service marks, and logos displayed on this website are the property of their respective owners and are used for identification purposes only.
              </li>
              <li>
                By using this website, you agree to abide by these terms and conditions. Simone Salis reserves the right to modify these terms and conditions at any time without notice.
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
  );
};

export default Disclaimer;
