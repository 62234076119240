import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
         <div className="modal-body p-4">
         <p>
           By using this website, you agree to comply with the following terms and conditions. Please review these terms carefully before using this website. If you do not agree to these terms, you should not use this website.
         </p>
         <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
           Terms of Use
         </h3>
         <p>
           Simone Salis owns and operates this website. All content, including text, images, and design elements, are the property of Simone Salis and are protected by copyright. Unauthorized use or distribution is strictly prohibited.
         </p>
         <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
           Part I – Access and Use of the Website
         </h5>
         <p>
           You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the website for personal, non-commercial purposes only. You agree not to use the website for any unlawful or prohibited purpose.
         </p>
         <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
           Part II – User Conduct
         </h5>
         <p>
           You agree not to use the website in any manner that could damage, disable, overburden, or impair the website or interfere with any other party's use and enjoyment of the website. You also agree not to attempt to gain unauthorized access to any computer systems or networks connected to the website.
         </p>
         <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
           Part III – Changes to Terms and Conditions
         </h5>
         <p>
           Simone Salis reserves the right to modify these terms and conditions at any time without notice. By using this website, you agree to be bound by the current version of these terms and conditions.
         </p>
         <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
           Privacy Policy
         </h3>
         <p>
           Simone Salis is committed to protecting your privacy. Please review our Privacy Policy for detailed information on how we collect, use, and protect your personal information.
         </p>
         <ol className="lh-lg">
           <li>
             Simone Salis may collect personal information from you, such as your name, email address, and other contact information, when you voluntarily submit it through the website.
           </li>
           <li>
             We use the personal information you provide to respond to your inquiries, provide requested services, and improve our website and services.
           </li>
           <li>
             Simone Salis will not sell, rent, or share your personal information with third parties without your consent, except as required by law or to protect our rights and property.
           </li>
           <li>
             We may use cookies and other tracking technologies to analyze website usage and improve the user experience.
           </li>
           <li>
             We implement reasonable security measures to protect your personal information, but we cannot guarantee the complete security of information transmitted over the internet.
           </li>
           <li>
             You have the right to access, correct, or delete your personal information held by us. To exercise these rights, please contact us.
           </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
